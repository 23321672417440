<template>
  <div class="management">
    <div class="management__left">
      <div
        v-for="item in tablels"
        :key="item.name"
        class="management__left--item"
        @click="efficacy(item.name)"
      >
        <img :src="item.path" alt="" srcset="" />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="management__right">
      <div class="management__right--head">
        <div class="management__right--head__item">
          <div class="management__right--head__item--left">{{ orderNum }}</div>
          <div class="management__right--head__item--righr">
            <img src="@/assets/check.png" alt="" srcset="" />
            <span>今日订单/单</span>
          </div>
        </div>
        <div class="management__right--head__item">
          <div class="management__right--head__item--left">
            {{ actualPrice }}
          </div>
          <div class="management__right--head__item--righr">
            <img src="@/assets/income.png" alt="" srcset="" />
            <span>今日收入/元</span>
          </div>
        </div>
      </div>
      <!-- 店铺详情的开始 -->
      <div class="management__right--body">
        <div class="management__right--body__label">店铺信息</div>
        <div class="management__right--body__address">
          店铺地址：{{ shopInfo.address }}
        </div>
        <div class="management__right--body__tips">营业执照：</div>
        <el-image
          class="businessLicense"
          :preview-src-list="[imgUrl + shopInfo.businessLicense]"
          :src="imgUrl + shopInfo.businessLicense"
        ></el-image>
        <div class="management__right--body__tips">店铺照片：</div>
        <el-image
          class="storePhotos"
          v-for="(item, index) in businessImgUrls"
          :key="index"
          :src="imgUrl + item"
        ></el-image>
        <div class="management__right--bottom" @click="efficacy">申请更改</div>
      </div>
    </div>
  </div>
</template>
<script>
// import uniPay from '@/utils/pay.js'
import { mapState } from 'vuex'
import cooksPng from '@/assets/cooks.png'
import tableGm from '@/assets/tableGm.png'
import spendingGm from '@/assets/spendingGm.png'
import assistant from '@/assets/assistant.png'
import members from '@/assets/members.png'
import accountGm from '@/assets/accountGm.png'
import marketing from '@/assets/marketing.png'
import print from '@/assets/print.png'
// import takeout from '@/assets/takeout.png';
import summaryGm from '@/assets/summaryGm.png'
import shopGm from '@/assets/shopGm.png'
import pay from '@/assets/pay.png'
import invoicing from '@/assets/invoicing.png'
import Decimal from 'decimal.js'
import orderInfo from '@/service/orderInfo.js'
export default {
  data () {
    return {
      orderNum: '',
      actualPrice: '',
      tablels: [
        { path: cooksPng, name: '菜品管理' },
        { path: tableGm, name: '桌台管理' },
        { path: spendingGm, name: '支出管理' },
        { path: assistant, name: '店员管理' },
        { path: members, name: '会员管理' },
        { path: accountGm, name: '账目管理' },
        { path: marketing, name: '营销管理' },
        { path: print, name: '打印管理' },
        // { path: takeout, name: '外卖管理' },
        { path: summaryGm, name: '综合报表' },
        { path: shopGm, name: '门店设置' },
        { path: pay, name: '支付管理' },
        { path: invoicing, name: '点单结账' }
      ]
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    }),
    businessImgUrls () {
      if (this.shopInfo.businessImgUrls) {
        return JSON.parse(this.shopInfo.businessImgUrls)
      }
      return []
    }
  },
  mounted () {
    this.getOrderNum()
  },
  methods: {
    async getOrderNum () {
      const formData = {
        businessId: this.shopInfo.id,
        orderEndTime: this.$dayjs().endOf('date').format('YYYY-MM-DD HH:mm:ss'),
        orderStartTime: this.$dayjs()
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss'),
        orderStatus: 3
      }
      const res = await orderInfo.getOrderList(formData)
      this.orderNum = res.data.length
      if (this.orderNum > 0) {
        this.actualPrice = res.data.reduce((pre, item) => {
          return new Decimal(pre).add(Number(item.actualPrice)).toNumber()
        }, 0)
      }
    },
    efficacy (item) {
      switch (item) {
        case '菜品管理':
          this.$router.push({
            path: '/layout/foodGm'
          })
          break
           case '桌台管理':
          this.$router.push({
            path: '/layout/tableGm'
          })
          break
        default:
          this.$message({
            message: '该功能暂未开放',
            type: 'warning'
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.management {
  height: 100%;
  display: flex;
  &__left {
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    flex: 1;
    min-width: 0px;
    padding-top: 72px;
    box-sizing: border-box;
    &--item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      float: left;
      cursor: pointer;
      margin-bottom: 44px;
      img {
        width: 60px;
        height: 60px;
      }
      span {
        color: #543e3e;
        font-weight: 400;
        font-size: 16px;
        margin-top: 20px;
      }
    }
  }
  &__right {
    width: 671px;
    height: 100%;
    margin-left: 56px;
    display: flex;
    flex-direction: column;
    &--head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__item {
        background: #ffffff;
        border-radius: 20px;
        width: 311px;
        height: 132px;
        display: flex;
        align-items: center;
        justify-content: center;
        &--left {
          color: #543e3e;
          font-weight: 900;
          font-size: 36px;
        }
        &--righr {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 36px;
          img {
            width: 38px;
            height: 38px;
          }
          span {
            margin-top: 27px;
            color: #543e3e;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
    &--body {
      flex: 1;
      margin-top: 22px;
      min-height: 0px;
      background-color: #ffffff;
      border-radius: 20px;
      padding: 0px 40px;
      &__label {
        margin-top: 36px;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
      }
      &__address {
        margin-top: 40px;
        color: #000000;
        font-weight: 700;
        font-size: 16px;
      }
      &__tips {
        color: #000000;
        font-weight: 700;
        font-size: 16px;
        margin-top: 38px;
        margin-bottom: 21px;
      }
    }
    &--bottom {
      background: #ffbb12;
      border-radius: 8px;
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 52px;
      cursor: pointer;
    }
  }
}
.businessLicense {
  width: 306px;
  height: 184px;
}
.storePhotos {
  width: 153px;
  height: 153px;
  margin-right: 22px;
}
</style>
