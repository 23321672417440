import request from '@/utils/request.js'

export default {
  // 提交订单
  orderInfoSave: (data) => {
    return request({
      url: '/order/orderInfo/save',
      data,
      method: 'post'
    })
  },
  // 查询订单详情
  getOrderById: (id) => {
    return request({
      url: '/order/orderInfo/getOrderById/' + id,
      method: 'post'
    })
  },
  // 修改订单支付类型
  editOrderPayType: (data) => {
    return request({
      url: '/order/orderInfo/editOrderPayType',
      data,
      method: 'post'
    })
  },
  // 时间段内订单list
  getOrderList: (data) => {
    return request({
      url: '/order/orderInfo/getOrderList',
      data,
      method: 'post'
    })
  },
  // 修改订单状态
  changeOrderStatus: (data) => {
    return request({
      url: '/order/orderInfo/changeOrderStatus',
      data,
      method: 'post'
    })
  },
  // 分页查询
  orderInfoPageOrder: (data) => {
    return request({
      url: '/order/orderInfo/pageOrder',
      data,
      method: 'post'
    })
  }
}
